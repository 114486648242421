import React from 'react'

function Backend() {
  return (
    <div className="skills__content">
    <h3 className="skills__title"> Design</h3>

    <div className="skills__box">
        <div className="skills__group">
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">Figma</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"100px"}}></div>
                    </div>
                </div>
            </div>

            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">Maya</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"100px"}}></div>
                    </div>
                </div>
            </div>

            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">Sketch</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"50px"}}></div>
                    </div>
                </div>
            </div>

            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">Mobile app</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"80px"}}></div>
                    </div>
                </div>
            </div>
        </div>    
        
        <div className="skills__group">
            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">PS</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"100px"}}></div>
                    </div>
                </div>
            </div>

            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">Webflow</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"50px"}}></div>
                    </div>
                </div>
            </div>

            <div className="skills__data">
                <i className="bx bx-badge-check"></i>
                <div>
                    <h3 className="skills__name">Adobe XD</h3>
                    <div className="skills__level">
                        <div className="skill__meater" style={{width:"90px"}}></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Backend